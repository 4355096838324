import React, { useRef, useState } from 'react';
import Layout from '@components/layout';
import HeroText from '@components/sections/heroText/heroText';
import Testimonial from '@components/sections/testimonial/testimonial';
import Segment from '@components/segment';
import { THEME_LIGHT } from '@/src/common/consts/theme';

const webinarId = 88514981450;

const IndexPage = () => {
  const [buttonText, setButtonText] = useState('Register');
  const emailRef = useRef(null);
  const firstNameRef = useRef(null);
  const lastNameRef = useRef(null);

  const onSubmit = (event) => {
    event.preventDefault();

    if (window.analytics) {
      window.analytics.track('webinar_registration', {
        email: emailRef.current.value,
        firstName: firstNameRef.current.value,
        lastName: lastNameRef.current.value,
        webinarId,
      });

      setButtonText('Registred!');
    }
  };

  return (
    <Layout
      lang="us"
      headerWitdh="header"
      topHeader
      dataTheme="white"
    >
      <Segment id={process.env.SEGMENT_MARKETING_LANDING_PAGES_STAGE_US} />

      <HeroText
        dataTheme={THEME_LIGHT}
        title="Zoom registration"
        heroTextMargin="margin-top--sm"
      />

      <section data-theme={THEME_LIGHT} className="CtaMailCard">
        <div
          className="container container--sm margin-bottom--lg"
          style={{ backgroundColor: '#edf4fc!important' }}
        >
          <div className="card col col--12 col--md-12 flex flex--center accessFormCard">
            <div className="text--component text--center">
              <form
                id="trialForm"
                method="post"
                className="validate form"
                data-goal="Onboarding Viewed"
                onSubmit={onSubmit}
                style={{ margin: '0 auto' }}
              >
                <div>
                  <input
                    ref={emailRef}
                    type="email"
                    autoCapitalize="off"
                    autoCorrect="off"
                    name="email"
                    id="email"
                    className="margin-right--xs form-control form-control--md form-control--white text--left margin-bottom--sm"
                    placeholder="Enter your email"
                    required
                  />
                  <input
                    ref={firstNameRef}
                    type="text"
                    autoCapitalize="off"
                    autoCorrect="off"
                    name="firstName"
                    id="firstName"
                    className="margin-right--xs form-control form-control--md form-control--white text--left margin-bottom--sm"
                    placeholder="Enter your first name"
                    required
                  />
                  <input
                    ref={lastNameRef}
                    type="text"
                    autoCapitalize="off"
                    autoCorrect="off"
                    name="lastName"
                    id="lastName"
                    className="margin-right--xs form-control form-control--md form-control--white text--left margin-bottom--sm"
                    placeholder="Enter your last name"
                    required
                  />
                  <input
                    id="register"
                    type="submit"
                    value={buttonText}
                    name="submit"
                    className="btn btn--primary btn--md btn--fullwidth-sm"
                    style={{ width: '100%' }}
                  />
                </div>
              </form>
            </div>
          </div>
        </div>
      </section>
    </Layout>
  );
};

export default IndexPage;
